import { createSelector } from "@reduxjs/toolkit";
import { pickBy } from "lodash";
import range from "lodash/range";
import sortBy from "lodash/sortBy";
import { RootState } from "../../app/store";
import { ISelectOption } from "../../app/types";
import { getElementStats } from "../elementStats";
import { getElementTypes } from "../elementTypes";
import { IPickLight } from "../entries";
import { getActiveEvent, getLastEvent, getNextEvent } from "../events";
import { getOverridesById } from "../overrides/selectors";
import { getWatched } from "../player/playerSlice";
import { getTeams, getTeamsWithUnstartedFixtures } from "../teams";
import { IElement, IElementFixture, IElementFixtureBlank } from "./types";

export const formatNameForSearching = (name: string): string => {
  const search = name.toLowerCase();
  const from = "ąàáäâãåæăćčĉęèéëêĝĥìíïîĵłľńňòóöőôõðøśșşšŝťțţŭùúüűûñÿýçżźž.";
  const to = "aaaaaaaaaccceeeeeghiiiijllnnoooooooossssstttuuuuuunyyczzz ";
  return search.replace(/.{1}/g, (c) => {
    const index = from.indexOf(c);
    return index === -1 ? c : to[index];
  });
};

export const getElementsById = createSelector(
  [
    getOverridesById,
    (state: RootState) => state.elements.byId,
    (_, eventId?) => eventId,
  ],
  (overridesById, elementsById, eventId) => {
    if (eventId && eventId in overridesById) {
      return {
        ...elementsById,
        ...overridesById[eventId].elements.byId,
      };
    } else {
      return elementsById;
    }
  }
);

// Returns all elements excluding those where removed is True
export const getFilteredElementsById = createSelector(
  [getElementsById],
  (elementsById) => pickBy(elementsById, (e) => !e.removed)
);

export const getElement = createSelector(
  [
    (state, _, eventId?) => getElementsById(state, eventId),
    (_, elementId) => elementId,
  ],
  (elementsById, elementId) => {
    return elementsById[elementId];
  }
);

export const getElements = createSelector(
  [(state, eventId?) => getElementsById(state, eventId)],
  (elements) => {
    return Object.keys(elements).map((e) => elements[e]);
  }
);

export const getElementsWithUnstartedFixtures = createSelector(
  [
    (state, eventId) => getElements(state, eventId),
    (state, eventId) => getTeamsWithUnstartedFixtures(state, eventId),
  ],
  (elements, teamIds) => {
    return elements.filter((element) => teamIds.includes(element.team));
  }
);

export const getElementControls = (state: RootState) => state.elements.controls;

export const getElementCount = createSelector(
  getElements,
  (elements) => elements.length
);

export const getElementTypeFilterOptions = createSelector(
  [(state: RootState) => getElementTypes(state, getActiveEvent(state).id)],
  (types): ISelectOption[] =>
    types.map((et) => ({
      label: et.plural_name,
      value: `et_${et.id}`,
    }))
);

export const getTeamFilterOptions = createSelector(
  getTeams,
  (teams): ISelectOption[] =>
    teams.map((t) => ({
      label: t.name,
      value: `te_${t.id}`,
    }))
);

export const getFilterOptions = createSelector(
  getElementTypeFilterOptions,
  getTeamFilterOptions,
  (types, teams) => [...types, ...teams]
);

export const getFilter = (filter: string) => {
  const filters = {
    element_type: 0,
    team: 0,
  };
  const filterRegexp = /^(te|et)_(\d+)$/;
  const matches = filter.match(filterRegexp);
  if (matches) {
    const key = matches[1];
    const value = parseInt(matches[2], 10);
    switch (key) {
      case "te":
        filters.team = value;
        break;
      case "et":
        filters.element_type = value;
        break;
      default:
        break;
    }
  }
  return filters;
};

export const getSafeSearchRegexp = (search: string) => {
  const safeValue = formatNameForSearching(search).replace(
    /[-/\\^$*+?.()|[\]{}]/g,
    "\\$&"
  );
  return new RegExp(`(^${safeValue}| ${safeValue})`);
};

export const filterElements = (
  elements: IElement[],
  filterString: string,
  watched: number[]
) => {
  if (filterString === "wl") {
    return watchedElements(elements, watched);
  }
  const filter = getFilter(filterString);
  return elements.filter((e) => {
    if (!filterString) {
      return false;
    }
    if (filter.team && filter.team !== e.team) {
      return false;
    }
    if (filter.element_type && filter.element_type !== e.element_type) {
      return false;
    }
    return true;
  });
};

export const watchedElements = (elements: IElement[], watched: number[]) =>
  elements.filter((e) => watched.indexOf(e.code) > -1);

export const maxCostFromElements = (elements: IElement[]) =>
  elements.length
    ? elements.reduce(
        (max, e) => (e.now_cost > max ? e.now_cost : max),
        elements[0].now_cost
      )
    : 0;

export const minCostFromElements = (elements: IElement[]) =>
  elements.length
    ? elements.reduce(
        (min, e) => (e.now_cost < min ? e.now_cost : min),
        elements[0].now_cost
      )
    : 0;

export const reduceElements = (
  elements: IElement[],
  search: string,
  maxCost: number,
  excludeRemoved?: boolean,
  isSpecial?: boolean,
  isYetToPlay?: boolean
) => {
  const searchRegexp = getSafeSearchRegexp(search);
  return elements.filter((e) => {
    if (search && !e.search_name.match(searchRegexp)) {
      return false;
    }
    if (maxCost && e.now_cost > maxCost) {
      return false;
    }

    if (excludeRemoved && e.removed) {
      return false;
    }

    if (isSpecial && !e.special) {
      return false;
    }

    if (isYetToPlay && !e.can_transact) {
      return false;
    }
    return true;
  });
};

const ascendingSort: Array<keyof IElement> = ["chance_of_playing_next_round"];

const stringSort: Array<keyof IElement> = ["news_added"];

export const sortElements = (elements: IElement[], sort: keyof IElement) =>
  elements.sort((a, b) => {
    let aSort, bSort;
    if (stringSort.indexOf(sort) > -1) {
      aSort = a[sort] || "";
      bSort = b[sort] || "";
    } else {
      // sort value must be either a float represented as a string or a number
      // all bets are off if not ...
      aSort = a[sort] || 0;
      bSort = b[sort] || 0;
      if (typeof aSort === "string" && typeof bSort === "string") {
        aSort = parseFloat(aSort);
        bSort = parseFloat(bSort);
      }
    }

    if (ascendingSort.indexOf(sort) > -1) {
      bSort = [aSort, (aSort = bSort)][0];
    }

    // Descending stat order
    if (aSort !== bSort) {
      return aSort > bSort ? -1 : 1;
    }

    // Ascending team order
    if (a.team !== b.team) {
      return a.team > b.team ? 1 : -1;
    }

    // Ascending element type order
    if (a.element_type !== b.element_type) {
      return a.element_type > b.element_type ? 1 : -1;
    }

    // Finally ascending id
    return a.id > b.id ? 1 : -1;
  });

export const getElementsFromControls = createSelector(
  (state: RootState) => getElements(state, getActiveEvent(state).id),
  getElementControls,
  getWatched,
  (elements, controls, watched) => {
    const filteredElements = filterElements(
      elements.filter((e) => controls.getUnavailable || e.status !== "u"),
      controls.filter,
      watched
    );

    return {
      data: sortElements(
        reduceElements(
          filteredElements,
          controls.search,
          controls.maxCost,
          controls.excludeRemoved,
          controls.getSpecial,
          controls.getYetToPlay
        ),
        controls.sort
      ),
      maxCost: maxCostFromElements(filteredElements),
      minCost: minCostFromElements(filteredElements),
    };
  }
);

export const getAverageCostByType = createSelector(
  (state: RootState) => getElements(state, getActiveEvent(state).id),
  (
    elements
  ): {
    [key: string]: number;
  } => {
    const elCosts: {
      [key: string]: {
        total: number;
        count: number;
      };
    } = {};
    elements.forEach((e) => {
      if (!elCosts[e.element_type]) {
        elCosts[e.element_type] = { total: 0, count: 0 };
      }
      elCosts[e.element_type].total += e.now_cost;
      elCosts[e.element_type].count++;
    });
    return Object.keys(elCosts).reduce(
      (memo, et) => ({
        ...memo,
        [et]: elCosts[et].total / elCosts[et].count,
      }),
      {}
    );
  }
);

export const getElementDialog = (state: RootState) => state.elements.dialog;

export const getFixturesById = (state: RootState) =>
  state.elements.fixturesById;

export const getFixtures = (state: RootState, elementId: number) => {
  const fixturesById = getFixturesById(state);
  return fixturesById[elementId] || [];
};

export const getFixturesWithBlanks = (state: RootState, elementId: number) => {
  const fixtures = getFixtures(state, elementId);
  const nxt = getNextEvent(state);
  const lst = getLastEvent(state);

  // No more events so just return any fixtures
  if (!nxt) {
    return fixtures;
  }

  // Pad out any blank events
  const fixturesWithBlanks: Array<IElementFixture | IElementFixtureBlank> = [];
  let expectedNextEventId = nxt.id;
  fixtures.forEach((f) => {
    // TBA fixtures are at the end
    if (!f.event) {
      fixturesWithBlanks.push(f);
      return;
    }
    if (f.event > expectedNextEventId) {
      range(expectedNextEventId, f.event).map((i) =>
        fixturesWithBlanks.push({ code: null, event: i })
      );
    }
    fixturesWithBlanks.push(f);
    expectedNextEventId = f.event + 1;
  });

  // Also need to consider missing fixture until the last event
  if (lst && lst.id >= expectedNextEventId) {
    range(expectedNextEventId, lst.id + 1).map((i) =>
      fixturesWithBlanks.push({ code: null, event: i })
    );
  }

  return fixturesWithBlanks;
};

export const getHistoryById = (state: RootState) => state.elements.historyById;

export const getHistory = (state: RootState, elementId: number) => {
  const historyById = getHistoryById(state);
  return historyById[elementId] || [];
};

export const getHistoryTotals = (state: RootState, elementId: number) => {
  const statNames = [
    ...getElementStats(state).map((s) => s.name),
    "total_points",
  ];
  const totals: Record<string, number | string> = statNames.reduce(
    (memo, n) => ({ ...memo, [n]: 0 }),
    {}
  );
  getHistory(state, elementId).forEach((h) => {
    statNames.forEach((s) => {
      if (typeof h[s] === "number") {
        totals[s] = Number(totals[s]) + Number(h[s]);
      } else {
        totals[s] = (Number(totals[s]) + Number(h[s])).toFixed(1);
      }
    });
  });
  return totals;
};

export const getSeasonHistoryById = (state: RootState) =>
  state.elements.seasonHistoryById;

export const getSeasonHistory = (state: RootState, elementId: number) => {
  const seasonHistoryById = getSeasonHistoryById(state);
  return seasonHistoryById[elementId] || [];
};

export const getElementsEventDataById = (state: RootState, eventId: number) =>
  state.elements.eventDataById[eventId] || null;

export const getElementEventData = (
  state: RootState,
  elementId: number,
  eventId: number
) =>
  state.elements.eventDataById[eventId]
    ? state.elements.eventDataById[eventId][elementId]
    : null;

export const getTotalsByType = (elements: IElement[]) =>
  sortBy(elements, "element_type").reduce<Record<string, number>>(
    (memo, e) => ({
      ...memo,
      [e.element_type]: memo[e.element_type] ? memo[e.element_type] + 1 : 1,
    }),
    {}
  );

export const getTotalsByTypeFromPicks = (picks: IPickLight[]) =>
  sortBy(picks, "element_type_id").reduce<Record<string, number>>(
    (memo, p) => ({
      ...memo,
      [p.element_type]: memo[p.element_type] ? memo[p.element_type] + 1 : 1,
    }),
    {}
  );

export const getFormation = (elements: IElement[]) => {
  const totals = getTotalsByType(elements);
  return sortBy(Object.keys(totals), (et) => Number(et))
    .map((et) => totals[et])
    .join("-");
};

export const getFormationFromPicks = (picks: IPickLight[]) => {
  const totals = getTotalsByTypeFromPicks(picks);
  return sortBy(Object.keys(totals), (et) => Number(et))
    .map((et) => totals[et])
    .join("-");
};
