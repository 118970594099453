import { IElementEventRaw, IElementEventRawDataTransformed } from "../elements";
import { IEvent, IEventStatusData } from "../events";
import { apiSlice } from "./apiSlice";

export const eventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEventLive: builder.query<IElementEventRawDataTransformed, number>({
      query: (eventId: number) => ({
        url: `/event/${eventId}/live`,
        method: "GET",
      }),
      transformResponse: (response: IElementEventRaw, meta, eventId) => {
        return {
          eventId: eventId,
          element_event_data: response,
        };
      },
    }),
    getEventStatus: builder.query<IEventStatusData, void>({
      query: () => ({
        url: "/event-status/",
        method: "GET",
      }),
    }),
    getEvents: builder.query<IEvent[], void>({
      query: () => ({
        url: "/events/",
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEventLiveQuery,
  useGetEventStatusQuery,
  useLazyGetEventsQuery,
} = eventsApi;
