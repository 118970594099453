import React from "react";
import Copy from "../components/Copy";
import HelpNav from "../components/HelpNav/HelpNav";
import { GridItem, GridWrapper, SidebarGrid } from "../components/Layout";
import SubHeading from "../components/SubHeading";
import Title from "../components/Title";

const Terms: React.FC = () => {
  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <GridItem>
          <Title>Help</Title>
        </GridItem>
        <GridItem>
          <div className="-mt-3">
            <HelpNav />
          </div>
        </GridItem>
        <GridItem>
          <SubHeading>
            2024/25 Fantasy Premier League Challenge – Terms & Conditions
          </SubHeading>
        </GridItem>
        <GridItem>
          <Copy>
            <p>
              In these Terms of Use, any use of the words "you", "yours" or
              similar expressions shall mean any user of this website
              whatsoever. Terms such as "we", "us, "our" or similar expressions
              shall mean the Football Association Premier League Limited.
            </p>
            <p>
              By Registering to participate in the Fantasy Premier League
              Challenge, a game made up of a series of challenges related to
              Fantasy Premier League (the "Game") you agree to be bound by and
              to abide by the following terms and conditions (the "Terms"). You
              should read these Terms before using the Game. These Terms apply
              to every occasion that you use the Game. If you do not agree or
              accept the Terms, you are advised not to use the Game.
            </p>
            <p>
              These Terms may be amended or modified, or new conditions may be
              imposed, at any time. Any such changes or additions will be
              reflected by an update of this page. You are therefore advised to
              check these Terms periodically for changes. Your continued use of
              the Game following the posting of changes to these Terms will mean
              you accept those changes and that such changes shall apply to your
              use of the Game after such changes have been posted.
            </p>
            <p>
              The promoter of the Game is the Football Association Premier
              League Limited, a company registered in England with company
              number 02719699 whose registered office is The Premier League,
              Brunel Building, 57 North Wharf Road, London, United Kingdom, W2
              1HQ ("we", "our" or the "Premier League").
            </p>
            <p>
              <strong>iOS users only:</strong>&nbsp; For the avoidance of doubt,
              Apple is not a sponsor, promoter, partner of, or in any way
              affiliated (or otherwise associated) with, the Game.
            </p>
            <br />
            <h4>Your use of the Game if you are under 18</h4>
            <p>Under 18s may Register to play the Game.</p>
            <p>
              If you are under 18, you may need your parent/guardian to help you
              with your use of the Game and with reading these Terms. If
              anything is hard to understand, please ask you parent/guardian to
              explain. If you still have any questions, you or your
              parent/guardian can contact us at:{" "}
              <a href="mailto:info@premierleague.com">info@premierleague.com</a>
              .
            </p>
            <p>
              If you are under 13, you cannot register for an account without
              the permission of your parent/guardian. You need to register if
              you want to play the Game.
            </p>
            <p>
              We may collect your personal information when you use the Game.
              You can find out more about how we use your personal information
              in our Questions and Answers section of our website. You can find
              a link here:&nbsp;
              <a href="https://www.premierleague.com/privacy-policy-child-q&as">
                https://www.premierleague.com/privacy-policy-child-q&as
              </a>
            </p>
            <br />
            <h4>Applications and Registration</h4>
            <ol>
              <li>
                <p>
                  All applications to participate in the Game must be made
                  online on the Premier League website at&nbsp;
                  <a href="www.premierleague.com">www.premierleague.com</a>
                  &nbsp;(the "Site") or via the Premier League mobile
                  application (the "App"). No applications to enter made in any
                  other manner will be accepted. Where your application is
                  accepted, you have successfully "Registered" and you will
                  become a "Player" in the Game. Each player is then able to
                  create a team or mini-league.
                </p>
              </li>
              <li>
                <p>
                  Registration for the Game is open to both UK and non-UK
                  residents. Employees (and immediate family members) of the
                  Premier League may enter the Game but are ineligible to win
                  any Prizes. If such person would otherwise win a Prize, the
                  Prize shall be awarded to the next placed Player.
                </p>
              </li>
              <li>
                <p>
                  Under 18s may Register to play the Game, however the
                  permission of a parent or guardian is required to receive a
                  Prize. The eligibility of an under 18 to win a Prize is set
                  out below. The Premier League will obtain the consent of your
                  parent or guardian if it wishes to use your name or team name
                  or any comments or details about you.
                </p>
              </li>
              <li>
                <p>
                  No purchase of any item or service is necessary in order to
                  enter the Game and no payment is required from you.
                </p>
              </li>
              <li>
                <p>
                  Registrations must not be made by agents or third parties. No
                  responsibility can be accepted for lost, delayed or incomplete
                  Registrations or Registrations not received by the Premier
                  League for any reason. Any such Registrations will be deemed
                  void (concerning which the decision of the Premier League
                  shall be final).
                </p>
              </li>
              <li>
                <p>
                  There is no limit on the number of Registrations per household
                  or organisation but only one Registration for the Game per
                  unique user of the Site or App is permitted. Individuals are
                  not permitted to register multiple accounts on the Site or
                  App. Players are permitted to compete in multiple leagues
                  within the Game. Players are not permitted to provide any
                  other person with access to their account on the Site or App
                  to play the Game or otherwise.
                </p>
              </li>
              <li>
                <p>
                  While there is no closing date for Registrations, and
                  applications can be made throughout the Premier League season,
                  the Premier League will publish deadlines from time to time by
                  which you must be Registered as a Player in order to qualify
                  for points awarded in respect of any round of matches in the
                  Premier League competition. Any such information on how and
                  when to enter for the Game forms part of these Terms and shall
                  be binding on you.
                </p>
              </li>
              <li>
                <p>
                  Players may (at their sole and absolute discretion) enable two
                  factor authentication on their account. Two factor
                  authentication improves the security of Players’ accounts.
                  Before enabling two factor authentication, we recommend that
                  Players read our guide on two factor authentication which can
                  be found here,&nbsp;
                  <a href="https://www.premierleague.com/news/2667341">
                    https://www.premierleague.com/news/2667341
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  The Premier League wishes to ensure the Game is an inclusive
                  and safe place for all Players. The Premier League reserves
                  the right to change the name of a Registration, team or league
                  or suspend and/or delete Registrations, teams or leagues that
                  contain names, words or phrases which are deemed solely by the
                  Premier League to be inappropriate, promotional, profane,
                  derogatory, discriminatory, divisive or offensive
                  (irrespective of the opinion of the Player).
                </p>
              </li>
              <li>
                <p>
                  In submitting a Registration and becoming a Player, you will
                  be providing personal data to the Premier League. Any personal
                  data which you do submit will be processed in accordance with
                  the Premier League's Privacy Policy, which can be found
                  here:&nbsp;
                  <a href="https://www.premierleague.com/privacy-policy">
                    https://www.premierleague.com/privacy-policy
                  </a>
                  ; and in accordance with relevant data protection legislation
                  including the UK General Data Protection Regulation and the
                  Data Protection Act 2018. If you are under 18, you can find
                  out more about how we use your personal information in our
                  Questions and Answers, which is available here:&nbsp;
                  <a href=" https://www.premierleague.com/privacy-policy-child-q&as">
                    https://www.premierleague.com/privacy-policy-child-q&as
                  </a>
                  .
                </p>
              </li>
              <li>
                <p>
                  We will only share your data in accordance with our Privacy
                  Policy and as set out in these Terms. Subject to paragraph 3,
                  you consent to The Premier League publishing the names or
                  teams of Players for promotional, marketing and publicity
                  purposes in connection to the Premier League or the Game
                  without any compensation or prior notice. If you have an
                  objection to this, please contact&nbsp;
                  <a href="mailto:support@mail.fantasy.premierleague.com">
                    support@mail.fantasy.premierleague.com
                  </a>
                  . The Premier League may also share your personal data if
                  required to do so by a competent authority or court within the
                  United Kingdom.
                  <br />
                  Where a Player is a winner of a Weekly Prize, the winner's
                  name may be published on the Site or App in accordance with
                  paragraph 23 and this paragraph 11. We will only share your
                  data in accordance with our Privacy Policy and as set out in
                  these Terms. Provided that you or your parent / guardian (as
                  appropriate) consent to The Premier League publishing the
                  names or teams of Players for promotional, marketing and
                  publicity purposes in connection to the Premier League or the
                  Game without any compensation or prior notice. If you have an
                  objection to this, please contact&nbsp;
                  <a href="mailto:support@mail.fantasy.premierleague.com">
                    support@mail.fantasy.premierleague.com
                  </a>
                  . Premier League may also share your personal data if required
                  to do so by a competent authority or court within the United
                  Kingdom. Where a Player is a winner of a Prize, the winner's
                  name may be published on the Site or App in accordance with
                  paragraph 23 and this paragraph 11.
                </p>
              </li>
              <li>
                <p>
                  Both UK and non-UK Players Registering for the Game may be
                  automatically entered into a "Country League". A Country
                  League is a mini-league consisting of Players from the same
                  Country. Entry into a Country League is automatic but
                  participation in no way obligates the Player to receive
                  marketing communications from the Premier League unless
                  otherwise indicated during Registration to the Site or App.
                </p>
              </li>
              <li>
                <p>
                  Players can establish mini-leagues in accordance with the Game
                  Rules. The Premier League does not permit any Player,
                  individual or organisation (save for the Premier League and
                  Clubs, Official Sponsors and Broadcast Licensees with the
                  express consent of the Premier League) to create or
                  participate in mini-leagues for any commercial purposes,
                  including but not limited to:
                </p>
                <ol type="a">
                  <li>
                    <p>charging for entry into a mini-league; and</p>
                  </li>
                  <li>
                    <p>
                      making entry into a mini-league conditional on the
                      purchase of services (including subscriptions) or goods.
                    </p>
                  </li>
                </ol>
                <p>
                  Should, in the opinion of the Premier League, a mini-league be
                  created for commercial purposes the Premier League reserves
                  the right to delete the mini-league and suspend or delete the
                  registration of any Player who is the administrator, or a
                  participant in, of the mini-league without liability. The
                  Premier League cannot be held responsible for the management
                  of any such mini-league or any loss suffered through Players'
                  involvement in them including but not limited to where they
                  are deleted by the Premier League.
                </p>
              </li>
            </ol>
            <br />
            <h4>The Game Rules</h4>
            <ol start={14}>
              <li>
                <p>
                  The Game will be governed by the Game Rules (as amended from
                  time to time) published on the Site (and, if relevant, via the
                  App) by the Premier League ("Game Rules"), which the Premier
                  League intends (but does not undertake) to change on a weekly
                  basis. The Premier League reserves the right to alter, amend
                  or supplement the Game Rules in its sole and absolute
                  discretion. You agree that no liability shall attach to the
                  Premier League as a result of any such change and are advised
                  to check the Game Rules regularly. Your continued use of the
                  Game following the posting of changes to the Game Rules will
                  mean you accept those changes and that such changes shall
                  apply to your use of the Game after such changes have been
                  posted. The Game Rules form part of these Terms and by
                  Registering for the Game, you accept the Game Rules.
                </p>
              </li>
              <li>
                <p>
                  The Premier League will be the sole decision-maker of any
                  matter of interpretation of the Game Rules and any aspect of
                  the content of, or playing of, the Game. The Premier League
                  will not enter into correspondence relating to such matters
                  and its decision as to any matter arising out of or in
                  connection with the Game Rules including but not limited to
                  the allocation of points to any Player and/or the award of any
                  Prize and/or any ranking or league table shall be final and
                  conclusive.
                </p>
              </li>
              <li>
                <p>
                  All Players (excluding those listed in paragraph 2) are
                  eligible to win the Prizes provided the winner in each case
                  has complied with these Terms. However, where a Player is
                  under the age of 18, they will not be eligible to win any cash
                  element of a Prize (if applicable) and parental or guardian
                  permission must be obtained. Such permission must include
                  confirmation that the parent/guardian agrees to the Player's
                  entry to the Game, they agree to publication of the Player’s
                  name as winner of the Prize, they agree to the Player being
                  awarded a Prize and will accompany the Player during any
                  travel connected to the Prize. For the avoidance of doubt,
                  this permission is separate to the permission that must be
                  obtained by all Players under the age of 13 to set up a
                  Premier League account, which is required to play the Game.
                </p>
              </li>
            </ol>
            <br />
            <h4>Prizes and Winner’s Details</h4>
            <ol start={17}>
              <li>
                <p>
                  The Premier League may choose to award prizes in respect of
                  the Game ("Prizes"). Such Prizes will be available at the sole
                  and absolute discretion of the Premier League and in
                  accordance with criteria set by it. The Premier League
                  reserves the right to alter and amend the Prizes or the
                  criteria for winning a Prize, where circumstances beyond its
                  reasonable control require it to do so.
                </p>
              </li>
              <li>
                <p>
                  Prizes are not transferable and are non-exchangeable. No cash
                  alternative will be offered in any circumstances. The Premier
                  League will make reasonable endeavours to accommodate any
                  needs or requirements of Prize recipients and where a Prize
                  includes travel or attendance at an event, the Premier League
                  will seek to agree dates with the recipient. However, where
                  the recipient is unavailable on the dates provided by the
                  Premier League, the Prize may be forfeited, or another
                  recipient selected. If any aspect of a Prize is cancelled or
                  postponed or the Premier is unable to award the Prize due to
                  circumstances beyond the Premier League’s control, including
                  but not limited to, travel cancellations, delays or
                  interruptions due to acts of God, acts and advice of
                  Governments, acts of war, epidemic, pandemic, natural
                  disasters, weather, acts of terrorism, national catastrophes,
                  a period of mourning following the death of a monarch, advice
                  against any travel by any Government or official authority for
                  any reason (including by way of example only and without
                  limitation by reason of any actual or potential terrorist
                  threat) and/or the World Health Organisation or any
                  internationally recognised medical authority for any reason or
                  an event of force majeure, the Premier League and its agencies
                  will not be responsible and that portion of the Prize will not
                  be awarded and no additional compensation will be provided. No
                  refunds or credit for changes to Prizes are allowed.
                </p>
              </li>
              <li>
                <p>
                  The Premier League intends (but does not undertake) for the
                  following Prizes to be available:
                </p>
                <ol type="a">
                  <li>
                    <p>
                      The first Prize ("Winner's Prize") will be awarded to the
                      Player who, according to the Premier League's
                      determination, is at the top of the overall Fantasy
                      Premier League Challenge leaderboard published by the
                      Premier League at the end of the 2024/25 season.
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          The Winner's Prize will be a 7-night break in the
                          United Kingdom for two people to include hospitality
                          at two Premier League matches (the identity of which
                          is determined by the Premier League) and visits to a
                          selection of popular tourist attractions in the UK.
                        </p>
                      </li>
                      <li>
                        <p>
                          The Winner's Prize includes travel (in the form of
                          BritRail passes or similar and for those who live
                          outside the UK only: one return flight for the winner
                          and a guest to the UK), and seven nights' hotel
                          accommodation on a bed & breakfast basis.
                        </p>
                      </li>
                      <li>
                        <p>
                          The Winner's Prize is also likely to include a Hublot
                          Watch, an official Premier League club shirt, an EA
                          SPORTS FC™ game, a laptop computer or SIM-free
                          smartphone, noise-cancelling headphones (in-ear or
                          on-ear), and personalised Fantasy Premier League
                          bundle consisting of a rucksack, T-shirt, travel mug,
                          thermal bottle, stress ball, pen, pad and key ring.
                          The precise specification and nature of these items
                          shall be at the sole and absolute discretion of the
                          Premier League.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      The second Prize ("Runner-up Prize") will be awarded to
                      the Player who according to the Premier League's
                      determination finishes in second place in the overall
                      Fantasy Premier League Challenge leaderboard published by
                      the Premier League at the end of the 2024/25 season.
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          The Runner-up Prize shall consist of a Trip for two
                          people to one Premier League match (the identity of
                          which is determined by the Premier League). The prize
                          includes travel (in the form of BritRail passes or
                          similar and for those who live outside the UK only:
                          one return flight for the winner and a guest to the
                          UK), three nights' accommodation including breakfast
                          and two hospitality match tickets.
                        </p>
                      </li>
                      <li>
                        <p>
                          The Runner-up's Prize is also likely to include an
                          official Premier League club shirt, an EA SPORTS FC™
                          game, a laptop computer or SIM-free smartphone,
                          noise-cancelling headphones (in-ear or on-ear), and
                          Fantasy Premier League bundle consisting of a
                          rucksack, T-shirt, travel mug, thermal bottle, stress
                          ball, pen, pad and key ring. The precise specification
                          and nature of these items shall be at the sole and
                          absolute discretion of the Premier League.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      The third Prize ("Third Prize") will be awarded to the
                      Player who according to the Premier League's determination
                      finishes in third place in the overall Fantasy Premier
                      League Challenge leaderboard published by the Premier
                      League at the end of the 2024/25 season.
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          The Third Prize shall consist of a trip for two people
                          to one Premier League match (the identity of which is
                          determined by the Premier League). The prize includes
                          travel (in the form of BritRail passes or similar and
                          for those who live outside the UK only: one return
                          flight for the winner and a guest to the UK), three
                          nights' accommodation including breakfast and two
                          hospitality match tickets.
                        </p>
                      </li>
                      <li>
                        <p>
                          Subject to availability and any unforeseen changes,
                          the Third Prize shall consist of an official Premier
                          League club shirt, an EA SPORTS FC™ game, a tablet
                          computer, and Fantasy Premier League bundle consisting
                          of a rucksack, T-shirt, travel mug, thermal bottle,
                          stress ball, pen, pad and key ring.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      The fourth Prize ("Fourth Prize") will be awarded to the
                      Player who according to the Premier League's determination
                      finishes in fourth place in the overall Fantasy Premier
                      League Challenge leaderboard published by the Premier
                      League at the end of the 2024/25 season.
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          Subject to availability and any unforeseen changes,
                          the Fourth Prize shall consist of an official Premier
                          League club shirt, an EA SPORTS FC™ game, a tablet
                          computer, noise-cancelling headphones (in-ear OR
                          on-ear), and Fantasy Premier League bundle consisting
                          of a rucksack, T-shirt, travel mug, thermal bottle,
                          stress ball, pen, pad and key ring.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      The fifth Prize ("Fifth Prize") will be awarded to the
                      Player who according to the Premier League's determination
                      finishes in fifth place in the overall Fantasy Premier
                      League Challenge leaderboard published by the Premier
                      League at the end of the 2024/25 season.
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          Subject to availability and any unforeseen changes,
                          the Fifth Prize shall consist of an official Premier
                          League club shirt, an EA SPORTS FC™ game,
                          noise-cancelling headphones (in-ear OR on-ear), and
                          Fantasy Premier League bundle consisting of a
                          rucksack, T-shirt, travel mug, thermal bottle, stress
                          ball, pen, pad and key ring.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      The sixth-to-tenth Prize ("Sixth-to-Tenth Prize") will be
                      awarded to the Players who according to the Premier
                      League's determination finishes in sixth, seventh, eighth,
                      ninth and tenth place in the overall Fantasy Premier
                      League Challenge leaderboard published by the Premier
                      League at the end of the 2024/25 season.
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          Subject to availability and any unforeseen changes,
                          the Sixth-to-Tenth Prize shall consist of an official
                          Premier League club shirt, an EA SPORTS FC™ game and
                          Fantasy Premier League bundle consisting of a
                          rucksack, T-shirt, travel mug, thermal bottle, stress
                          ball, pen, pad and key ring.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      The eleventh-to-twentieth Prize ("Eleventh-to-Twentieth
                      Prize") will be awarded to the Players who according to
                      the Premier League's determination finish in eleventh,
                      twelfth, thirteenth, fourteenth, fifteenth, sixteenth,
                      seventeenth, eighteenth, nineteenth and twentieth place in
                      the overall Fantasy Premier League Challenge leaderboard
                      published by the Premier League at the end of the 2024/25
                      season.
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          Subject to availability and any unforeseen changes,
                          the Eleventh-to-twentieth Prize shall consist of a
                          Fantasy Premier League bundle consisting of a
                          rucksack, T-shirt, travel mug, thermal bottle, stress
                          ball, pen, pad and key ring.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      A "Weekly Prize" awarded to the Player who according to
                      the Premier League's determination is at the top of the
                      weekly Fantasy Premier League Challenge leaderboard
                      published by the Premier League at the end of each weekly
                      period during the season. The exact timing of when to
                      select a winner and award this Prize is at the sole and
                      absolute discretion of the Premier League.
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          Subject to availability and any unforeseen changes,
                          the Weekly Prize shall consist of an official Premier
                          League club shirt, an EA SPORTS FC™ game and a Fantasy
                          Premier League bundle consisting of a rucksack,
                          T-shirt, travel mug, thermal bottle, stress ball, pen,
                          pad and key ring.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      From time to time, an “Event Prize” may be awarded by the
                      Premier League to the Player who according to the Premier
                      League’s determination finished in first place in a
                      particular challenge at the end of that challenge. The
                      nature of each challenge and the exact timing of each
                      challenge period is at the sole and absolute discretion of
                      the Premier League. The exact timing of when to select a
                      winner and award an Event Prize is also at the sole and
                      absolute discretion of the Premier League. The precise
                      specification and nature of each Event Prize shall be at
                      the sole and absolute discretion of the Premier League and
                      is subject to availability and any unforeseen changes. Not
                      all winners of a challenge will be awarded a prize.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>In the event:</p>
                <ol type="a">
                  <li>
                    <p>
                      more than one Player is at the top of the weekly Fantasy
                      Premier League Challenge leaderboard;
                    </p>
                  </li>
                  <li>
                    <p>there is a tie for the Weekly Second Place Prize; or</p>
                  </li>
                  <li>
                    <p>there is a tie for the Weekly Third Place Prize,</p>
                    <p>
                      the relevant prizes shall be awarded to those teams which
                      were first to register on the Site and if this is also
                      tied then the winner shall be selected at random by the
                      Premier League. The Premier League's decision will be
                      final and no correspondence will be entered into.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  Winners will be notified of their success by email in respect
                  of the Prizes, using the email address connected to the
                  Winner’s account. Each winner must respond to such
                  notification within twenty-eight (28) days to confirm that
                  they wish to receive their Prize. In the event that a winner
                  does not provide such confirmation, the Premier League
                  reserves the right to forfeit the Prize. Prizes are notified
                  within four weeks in respect of which such Prize is awarded,
                  at which time winners must confirm their contact details prior
                  to the Prize in question being delivered.
                </p>
              </li>
              <li>
                <p>
                  The Premier League may wish to use the name and photograph of
                  winners for promotional, marketing and publicity purposes in
                  any and all media worldwide. The Premier League will contact
                  winners to request their consent to their image being used.
                  Where a Player is under 18, parental consent will also be
                  required.
                </p>
              </li>
              <li>
                <p>
                  Provided the Premier League has obtained all requested
                  consents from winners (or as appropriate their parents /
                  guardians, the winners' names will be published on the Site
                  within four weeks in respect of which the Prize is awarded; or
                  alternatively winner's names can be obtained by sending a
                  stamped self-addressed envelope to Fantasy Premier League,
                  Premier League, Brunel Building, 57 North Wharf Road, London,
                  United Kingdom, W2 1HQ.
                </p>
              </li>
            </ol>
            <br />
            <h4>Player Responsibilities</h4>
            <ol start={24}>
              <li>
                <p>
                  You warrant and agree that you shall not upload, post,
                  transmit to, distribute or otherwise publish through the Game
                  or in relation to the Game any information, content or
                  materials that:
                </p>
                <ol type="a">
                  <li>
                    <p>
                      are protected by copyright, or other proprietary or
                      intellectual property right, or derivative works with
                      respect thereto, except as provided herein or without
                      first obtaining permission from the Premier League or the
                      copyright owner;
                    </p>
                  </li>
                  <li>
                    <p>
                      are unlawful, threatening, harassing, profane, tortious,
                      defamatory, discriminatory, vulgar, obscene, libellous,
                      deceptive, fraudulent, contains explicit or graphic
                      descriptions or accounts of sexual acts (including, but
                      not limited to, sexual language of a violent or
                      threatening nature directed at another individual or group
                      of individuals), invasive of another's privacy, or
                      hateful;
                    </p>
                  </li>
                  <li>
                    <p>
                      restrict or inhibit any other user from using and enjoying
                      the Game,
                    </p>
                  </li>
                  <li>
                    <p>
                      constitute or encourage conduct that would constitute a
                      criminal offence or give rise to civil liability, or
                    </p>
                  </li>
                  <li>
                    <p>
                      contain a virus or other harmful component or similar
                      computer code designed to adversely affect the operation
                      of any computer software or hardware, advertising of any
                      kind, or false or misleading indications of origin or
                      statements of fact.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>You also warrant and agree that you shall not:</p>
                <ol type="a">
                  <li>
                    <p>
                      impersonate, or misrepresent your affiliation with any
                      other person or entity;
                    </p>
                  </li>
                  <li>
                    <p>
                      upload, post, publish, transmit, reproduce, distribute or
                      in any way exploit any information or other material
                      obtained through the Game for commercial purposes (other
                      than as expressly permitted by the provider of such
                      information or other material);
                    </p>
                  </li>
                  <li>
                    <p>engage in spamming or flooding;</p>
                  </li>
                  <li>
                    <p>
                      use automated systems to access the Game and extract
                      information from the Game;
                    </p>
                  </li>
                  <li>
                    <p>
                      attempt to gain unauthorised access to other computer
                      systems through the Game;
                    </p>
                  </li>
                  <li>
                    <p>
                      bring the Game or the Premier League into disrepute; or
                    </p>
                  </li>
                  <li>
                    <p>create mini-leagues for commercial purposes.</p>
                  </li>
                </ol>
              </li>

              <li>
                <p>
                  You warrant and agree that, while participating in the Game,
                  you shall keep passwords for your account secure and procure
                  that your password is frequently changed and kept confidential
                  and you shall not allow any other person access to your
                  account nor share or transfer registration or control of your
                  account with or to another person.
                </p>
              </li>
              <li>
                <p>
                  The Premier League is the owner of all intellectual property
                  rights in the Game, including without limitation in the
                  associated graphics, trade marks, logos, branding, images and
                  any Game data. Such rights must not be used by any Player in
                  any way without the prior written consent of the Premier
                  League. The Premier League’s clubs retain their own trade
                  marks, which must not be reproduced without the relevant
                  club’s prior written consent. Except as otherwise expressly
                  permitted herein, you may not upload, post, publish,
                  reproduce, transmit or distribute in any way any component of
                  the Game itself or derivative works with respect thereto, as
                  the Game is copyrighted as a collective work under the
                  copyright laws of England and Wales.
                </p>
              </li>
            </ol>
            <br />
            <h4>Premier League Responsibility and Liability</h4>
            <ol start={28}>
              <li>
                <p>
                  The extent of the Premier League's responsibility to you has
                  been determined in the context of the following:
                </p>
                <ol type="a">
                  <li>
                    <p>
                      the Game is provided to you free of charge and on an “as
                      is” basis;
                    </p>
                  </li>
                  <li>
                    <p>
                      you are responsible for any action you do or do not take
                      as a result of the Game and the information therein;
                    </p>
                  </li>
                  <li>
                    <p>
                      you are responsible for ensuring that your equipment is
                      enabled with appropriate up-to-date virus checking
                      software; and
                    </p>
                  </li>
                  <li>
                    <p>
                      you are responsible for implementing two factor
                      authentication on your account.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  While the Premier League will endeavour to ensure that the
                  Game is available to you at all times and that the contents
                  are correct and accurate, the Premier League cannot make any
                  legal commitment to you that this will be the case. However,
                  the Premier League will exercise reasonable skill and care in
                  providing any service to you.
                </p>
              </li>
              <li>
                <p>
                  The Premier League reserves the right at any time to
                  temporarily or permanently modify or discontinue the Game with
                  or without prior notice (due to reasons outside its reasonable
                  control). In the event that Premier League permanently
                  discontinues the Game pursuant to this paragraph, you shall be
                  released from your obligations to Premier League under these
                  Terms.
                </p>
              </li>
              <li>
                <p>
                  Nothing in these Terms limits or excludes Premier League's
                  liability for (i) death or personal injury caused by our
                  negligence; (ii) liability arising from fraud or fraudulent
                  misrepresentation; or (iii) any other reason or by any cause
                  which cannot be excluded by law.
                </p>
              </li>
              <li>
                <p>
                  The Premier League accepts no liability to you for any damage,
                  loss, liabilities, injury or disappointment incurred or
                  suffered by you as a result of entering the Game or accepting
                  a Prize including but not limited to the following types of
                  loss which you may suffer as a result of your entry to the
                  Game:
                </p>
                <ol type="a">
                  <li>
                    <p>
                      loss which was not foreseeable to you and the Premier
                      League when you first applied for the Game (even if that
                      loss results from the Premier League's failure to comply
                      with these terms or its negligence);
                    </p>
                  </li>
                  <li>
                    <p>
                      any business loss you may suffer, including loss of
                      revenue, profits, or anticipated savings (whether those
                      losses are the direct or indirect result of the Premier
                      League's default);
                    </p>
                  </li>
                  <li>
                    <p>
                      loss which you suffer other than as a result of our
                      failure to comply with these Terms or our negligence or
                      breach of statutory duty;
                    </p>
                  </li>
                  <li>
                    <p>
                      any loss or damage to your account resulting from your
                      failure to maintain the security of your account and/or
                      password; and/or
                    </p>
                  </li>
                  <li>
                    <p>
                      any loss which you may suffer as a result of or in
                      connection with or arising out of any Prize.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <h4>Breach</h4>
            <ol start={33}>
              <li>
                <p>
                  Any breach by you of the Game Rules from time to time shall
                  also be a breach of these Terms.
                </p>
              </li>
              <li>
                <p>
                  In the event of any breach by you of these Terms the Premier
                  League reserves the right in its sole discretion to:
                </p>
                <ol type="a">
                  <li>
                    <p>
                      permanently or temporarily refuse you entry to the Game;
                    </p>
                  </li>
                  <li>
                    <p>disqualify you from the Game;</p>
                  </li>
                  <li>
                    <p>modify, delete and/or suspend your Registration;</p>
                  </li>
                  <li>
                    <p>
                      delete all related information associated with your
                      Registration; and/or
                    </p>
                  </li>
                  <li>
                    <p>
                      require any reasonable amendment to your Registration.
                    </p>
                  </li>
                </ol>
                <p>
                  All such decisions by the Premier League will be final and no
                  correspondence will be entered into.
                </p>
              </li>
              <li>
                <p>
                  If you are barred or disqualified from Registering, you shall
                  not be eligible to participate in the Game under any other
                  identity or team name. Any such decision by the Premier League
                  shall be final.
                </p>
              </li>
            </ol>
            <br />
            <h4>General</h4>
            <ol start={52}>
              <li>
                <p>
                  If any of these Terms are determined to be illegal, invalid,
                  or otherwise unenforceable then the remaining terms shall
                  continue in full force and effect.
                </p>
              </li>
              <li>
                <p>
                  The Terms shall be governed by and construed in accordance
                  with the laws of England and Wales and the parties agree to
                  submit to the exclusive jurisdiction of the Courts of England
                  and Wales.
                </p>
              </li>
            </ol>
          </Copy>
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export default Terms;
