interface PhaseAssets {
  title: string;
  description: string;
}

export const getPhaseAssets = (phaseId: number) => {
  const PhaseAssets: Record<number, PhaseAssets> = {
    40: {
      title: "5-a-side",
      description:
        "Introducing four straight Gameweeks of 5-a-side action. Compete for the best rank in the first FPL Challenge Event!",
    },
  };
  // check PhaseAssets has a key for the phase otherwise return null
  return Object.hasOwn(PhaseAssets, phaseId) ? PhaseAssets[phaseId] : null;
};
