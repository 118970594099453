import { hiDPI, rgba } from "polished";
import styled from "styled-components";
import pattern1_1236 from "../../img/pattern/pattern-1-1236.png";
import pattern1_618 from "../../img/pattern/pattern-1-618.png";

export const StyledPhaseSummary = styled.div`
  padding: ${(props) => props.theme.space[2]};
  border-radius: ${(props) => props.theme.space[2]};
  overflow: hidden;
  background-size: 493px 678px, auto;
  background-position: left -35px bottom -196px, 0, 0;
  background-repeat: no-repeat;
  background-image: url(${pattern1_618}),
    linear-gradient(to right, #05f182, #00ece3);

  ${hiDPI(2)} {
    background-image: url(${pattern1_1236}),
      linear-gradient(to right, #05f182, #00ece3);
  }

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    padding: ${(props) => props.theme.space[6]};
  }
`;

export const PhaseInfo = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    display: flex;
  }
`;

export const PhaseSummaryHeader = styled.div`
  padding: 0 ${(props) => props.theme.space[4]};
  margin-bottom: ${(props) => props.theme.space[3]};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 55%;
  }
`;

export const PhaseEventsTitle = styled.span`
  display: block;
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const PhaseName = styled.span`
  display: block;
  font-family: ${(props) => props.theme.fonts.heavy};
  font-size: 2.5rem;
`;

export const PhaseDescription = styled.p`
  font-size: ${(props) => props.theme.fontSizes[0]};
`;

export const PhaseStats = styled.div`
  display: flex;
  gap: ${(props) => props.theme.space[3]};
  padding: ${(props) => props.theme.space[3]};
  border-radius: ${(props) => props.theme.space[2]};
  background-color: ${(props) => rgba(props.theme.colors.white, 0.7)};

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    flex: 0 0 45%;
  }
`;

export const PhaseStatsMain = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: ${(props) => props.theme.space[3]};
`;

export const PhaseStatsSecondary = styled.div`
  flex: 2;
  padding-right: ${(props) => props.theme.space[3]};
`;

export const PhaseStat = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => rgba(props.theme.colors.black, 0.1)};
  }
`;

export const PhaseStatHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[1]};
  line-height: 2;
`;

export const PhaseStatValue = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: ${(props) => props.theme.fontSizes[3]};
`;

export const PhaseStatMainHeading = styled.h3`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: ${(props) => props.theme.fontSizes[2]};
  line-height: 1;
`;

export const PhaseStatMainValue = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 4rem;
  line-height: 1;
`;

export const PhaseSummaryDivider = styled.hr`
  margin: ${(props) => props.theme.space[3]} 0;
  border: 0;
  border-top: 1px solid ${(props) => rgba(props.theme.colors.primary, 0.08)};
`;
