import { createAsyncThunk } from "@reduxjs/toolkit";
import { IThunkApi } from "../../app/types";
import { bootstrapApi } from "../api/bootstrap";
import { elementsApi } from "../api/elements";
import { fixturesApi } from "../api/fixtures";
import { getActiveEvent } from "./selectors";
import { IEvent } from "./types";

// A thunk to encapsulate the multiple API calls that we need to
// call whenever we change events
export const doEventChange = createAsyncThunk<void, IEvent, IThunkApi>(
  "events/doEventChange",
  async (event: IEvent, { dispatch }) => {
    const eventDispatches: Promise<any>[] = [
      // Fixtures
      dispatch(
        fixturesApi.endpoints.fetchFixtures.initiate(event.id, {
          forceRefetch: true,
        })
      ),
    ];
    if (event.released) {
      // Event specific data
      eventDispatches.push(
        dispatch(
          bootstrapApi.endpoints.getBootstrapEvent.initiate(event.id, {
            forceRefetch: true,
          })
        )
      );
    }
    if (event.is_current || event.finished) {
      // Points data
      eventDispatches.push(
        dispatch(
          elementsApi.endpoints.fetchEventLive.initiate(event.id, {
            forceRefetch: true,
          })
        )
      );
    }
    await Promise.all(eventDispatches);
  }
);

export const doFetchLatestEventData = createAsyncThunk<void, void, IThunkApi>(
  "events/doFetchLatestEventData",
  async (_: void, { dispatch, getState }) => {
    const activeEvent = getActiveEvent(getState());

    if (activeEvent.is_current) {
      const elementsResponse = dispatch(
        elementsApi.endpoints.getElementsForEvent.initiate(activeEvent.id, {
          forceRefetch: true,
        })
      );
      const eventLive = dispatch(
        elementsApi.endpoints.fetchEventLive.initiate(activeEvent.id, {
          forceRefetch: true,
        })
      );
      const fixturesLive = dispatch(
        fixturesApi.endpoints.fetchFixtures.initiate(activeEvent.id, {
          forceRefetch: true,
        })
      );
      await Promise.all([elementsResponse, eventLive, fixturesLive]);
    }
  }
);
