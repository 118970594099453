import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { bootstrapApi } from "../api/bootstrap";
import { elementsApi } from "../api/elements";
import { IGetBootstrapEventResponse } from "../bootstrap";
import {
  formatNameForSearching,
  IElement,
  IElementsForEvent,
} from "../elements";
import { IElementType } from "../elementTypes";
import { IOverridesState } from "./types";

const initialState: IOverridesState = {
  byId: {},
};

const overrides = createSlice({
  name: "overrides",
  initialState,
  reducers: {
    addOverrides: (
      state,
      action: PayloadAction<IGetBootstrapEventResponse>
    ) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.event]: {
          ...action.payload,
          elements: {
            byId: Object.fromEntries(
              action.payload.elements.map((element: IElement) => [
                element.id,
                {
                  ...element,
                  search_name: formatNameForSearching(element.web_name),
                },
              ])
            ),
          },
          element_types: {
            byId: Object.fromEntries(
              action.payload.element_types.map((elementType: IElementType) => [
                elementType.id,
                {
                  ...elementType,
                },
              ])
            ),
          },
        },
      },
    }),
    addElementOverrides: (state, action: PayloadAction<IElementsForEvent>) => ({
      ...state,
      byId: {
        ...state.byId,
        [action.payload.event_id]: {
          ...state.byId[action.payload.event_id],
          elements: {
            byId: Object.fromEntries(
              action.payload.data.map((element: IElement) => [
                element.id,
                {
                  ...element,
                  search_name: formatNameForSearching(element.web_name),
                },
              ])
            ),
          },
        },
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapEvent.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapEventResponse>) =>
        overrides.caseReducers.addOverrides(state, action)
    );
    builder.addMatcher(
      elementsApi.endpoints.getElementsForEvent.matchFulfilled,
      (state, action: PayloadAction<IElementsForEvent>) =>
        overrides.caseReducers.addElementOverrides(state, {
          ...action,
          payload: action.payload,
        })
    );
  },
});

export const { addOverrides, addElementOverrides } = overrides.actions;

export default overrides.reducer;
