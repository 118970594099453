import { createSelector } from "@reduxjs/toolkit";
import { range, sum } from "lodash";
import { RootState } from "../../app/store";

import { getActiveEvent } from "../events";
import { getRules } from "../game";
import { getOverridesById } from "../overrides";
import { IElementType, IElementTypesBySquadPosition } from "./types";

export const getElementTypesById = createSelector(
  [
    getOverridesById,
    (state: RootState) => state.elementTypes.byId,
    (_, eventId?) => eventId,
  ],
  (overridesById, elementTypesById, eventId) => {
    if (eventId && eventId in overridesById) {
      return {
        ...elementTypesById,
        ...overridesById[eventId].element_types.byId,
      };
    } else {
      return elementTypesById;
    }
  }
);

export const getElementTypes = createSelector(
  [(state, eventId?) => getElementTypesById(state, eventId)],
  (elementTypesById) =>
    Object.keys(elementTypesById).map((et) => elementTypesById[et])
);

export const getElementTypesBySquadPosition = createSelector(
  [(state) => getElementTypes(state, getActiveEvent(state).id)],
  (types) => {
    const data: IElementTypesBySquadPosition = {};

    // First pass to set types by position
    let count = 1;
    types.forEach((et) => {
      range(et.squad_select).forEach(() => {
        data[count] = { lastType: null, nextType: null, thisType: et };
        count++;
      });
    });
    // Second pass to set last and next types which help with rendering
    count = 1;
    types.forEach((et) => {
      range(et.squad_select).forEach(() => {
        if (data[count - 1]) {
          data[count].lastType = data[count - 1].thisType;
        }
        if (data[count + 1]) {
          data[count].nextType = data[count + 1].thisType;
        }
        count++;
      });
    });
    return data;
  }
);

export const getPossibleFormations = createSelector(
  [
    (state) => getElementTypes(state, getActiveEvent(state).id),
    (state) => getRules(state, getActiveEvent(state).id),
  ],
  (elementTypes, rules) => {
    function getFormations(ets: IElementType[], current: number[] = []) {
      if (current.length === ets.length) {
        return [current.join("-")];
      }

      const currentEt = ets[current.length];
      let formations: string[] = [];

      for (
        let i = currentEt.squad_min_play;
        i <= currentEt.squad_max_play;
        i++
      ) {
        formations = formations.concat(getFormations(ets, [...current, i]));
      }

      return formations;
    }

    return getFormations(elementTypes).filter((formation: string) => {
      return (
        sum([...formation.split("-")].map((n) => +n)) === rules?.squad_squadplay
      );
    });
  }
);
