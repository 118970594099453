import { size } from "polished";
import Slider from "react-slick";
import styled from "styled-components";
import leftArrow from "../../img/icons/arrow-left-white.svg";
import rightArrow from "../../img/icons/arrow-right-white.svg";

// Stop slick getting effed up when inside display: grid!
// https://github.com/akiran/react-slick/issues/1571
export const SliderWrap = styled.div`
  /* minus the total x padding of all containers around it */
  max-width: calc(100vw - 4rem);

  @media (min-width: ${(props) => props.theme.breakpoints[4]}) {
    max-width: calc(66.666vw - 4rem);
  }

  @media (min-width: ${(props) => props.theme.breakpoints[6]}) {
    max-width: 867px;
  }
`;

export const EventSlider = styled(Slider)`
  padding-top: 3.6rem;

  .slick-prev {
    left: auto;
    right: 3.2rem;

    &::before {
      background: url(${leftArrow});
    }
  }

  .slick-next {
    right: 0;

    &::before {
      background: url(${rightArrow});
    }
  }

  .slick-prev,
  .slick-next {
    top: 0;
    transform: none;
    bottom: auto;
    width: auto;
    height: auto;
    transition: all 0.2s;
    padding: 0.6rem;
    border-radius: ${(props) => props.theme.radii[1]};
    background-color: ${(props) => props.theme.colors.primary};

    &::before {
      ${size(16)};
      content: "";
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 14px 24px;
      opacity: 1;
    }
  }

  .slick-disabled {
    cursor: default;

    &::before {
      opacity: 0.5;
    }
  }

  .slick-list {
    overflow: visible;
  }

  .slick-list,
  .slick-track {
    min-height: 0px;
    min-width: 0px;
  }

  .slick-slide:not(:last-child) {
    padding-right: ${(props) => props.theme.space[3]};
  }
`;
