import { RootState } from "../../app/store";

export const getRules = (state: RootState, eventId?: number) => {
  if (state.game.settings) {
    if (eventId && eventId in state.overrides.byId) {
      return {
        ...state.game.settings.rules,
        ...state.overrides.byId[eventId].rules,
      };
    } else {
      return state.game.settings.rules;
    }
  } else {
    return null;
  }
};

export const getScoring = (state: RootState, eventId?: number) => {
  if (state.game.settings) {
    if (eventId && eventId in state.overrides.byId) {
      return {
        ...state.game.settings.scoring,
        ...state.overrides.byId[eventId].scoring,
      };
    } else {
      return state.game.settings.scoring;
    }
  } else {
    return null;
  }
};

export const getTotalPlayers = (state: RootState) => state.game.total_players;
