import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { myTeamApi } from "../api/myTeam";
import { IMyTeamResponseData } from "../myTeam";
import { IChipState, IPotentialChip } from "./types";

const someActiveOrProposed = (chips: IPotentialChip[]) =>
  chips.some(
    (c) => c.status_for_entry === "active" || c.status_for_entry === "proposed"
  );

const initialState: IChipState = {
  potential: [],
};

const chips = createSlice({
  name: "chips",
  initialState,
  reducers: {
    proposeAvailableChip: (state, action: PayloadAction<string>) => {
      if (!someActiveOrProposed(state.potential)) {
        state.potential = state.potential.map((c) => ({
          ...c,
          status_for_entry:
            c.name === action.payload &&
            c.status_for_entry.match(/^(available|unavailable)$/)
              ? "proposed"
              : c.status_for_entry,
        }));
      }
    },
    cancelProposedChip: (state, action: PayloadAction<string>) => {
      state.potential = state.potential.map((c) => ({
        ...c,
        status_for_entry:
          c.name === action.payload && c.status_for_entry === "proposed"
            ? "available"
            : c.status_for_entry,
      }));
    },
    cancelActiveChip: (state, action: PayloadAction<string>) => {
      state.potential = state.potential.map((c) => ({
        ...c,
        status_for_entry:
          c.name === action.payload &&
          c.status_for_entry === "active" &&
          c.chip_type === "team"
            ? "cancelled"
            : c.status_for_entry,
      }));
    },
    restoreCancelledChip: (state, action: PayloadAction<string>) => {
      if (!someActiveOrProposed(state.potential)) {
        state.potential = state.potential.map((c) => ({
          ...c,
          status_for_entry:
            c.name === action.payload && c.status_for_entry === "cancelled"
              ? "active"
              : c.status_for_entry,
        }));
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      myTeamApi.endpoints.fetchMyTeam.matchFulfilled,
      (state, action: PayloadAction<IMyTeamResponseData>) => {
        state.potential = action.payload.data.chips;
      }
    );
    builder.addMatcher(
      myTeamApi.endpoints.saveMyTeam.matchFulfilled,
      (state, action: PayloadAction<IMyTeamResponseData>) => {
        state.potential = action.payload.data.chips;
      }
    );
  },
});

export const {
  cancelActiveChip,
  cancelProposedChip,
  proposeAvailableChip,
  restoreCancelledChip,
} = chips.actions;

export default chips.reducer;
