import React, { useState } from "react";
import LeagueUpdateDiaglog from "../../dialogs/LeagueUpdateDialog";
import { useAppDispatch } from "../../rtk-core/src/app/hooks";
import {
  IPrivateLeagueAdmin,
  IUpdateClassicLeagueData,
  LeagueCodePrivacy,
  updateClassicLeague,
} from "../../rtk-core/src/features/leagues";
import Button from "../Button";
import Copy from "../Copy";
import {
  CheckboxField,
  FieldWrap,
  InputField,
  SelectField,
} from "../FieldRenderers";
import SubHeading from "../SubHeading";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

const ClassicDetails: React.FC<IOwnProps> = ({ league }) => {
  const [state, setState] = useState({
    closed: league.closed,
    codePrivacy: league.code_privacy as LeagueCodePrivacy,
    hasCup: league.has_cup,
    name: league.name,
    startEvent: league.start_event,
  });
  const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const apiDataFromState = (): IUpdateClassicLeagueData => ({
    admin_entry: league.admin_entry,
    closed: state.closed,
    has_cup: state.hasCup,
    code_privacy: state.codePrivacy,
    name: state.name,
    start_event: state.startEvent,
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState({ ...state, name: e.target.value });

  const handleCodePrivacyChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setState({ ...state, codePrivacy: e.target.value as LeagueCodePrivacy });

  const handleClosedChange = (e: React.FormEvent<HTMLInputElement>) =>
    setState({ ...state, closed: e.currentTarget.checked });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      updateClassicLeague({ leagueId: league.id, data: apiDataFromState() })
    ).then(() => setShowUpdateDialog(true));
  };

  const disabled = !state.name || state.startEvent === 0;

  const privacyOptions = {
    a: "All game players",
    l: "League members",
    p: "League administrator",
  };

  type PrivacyOptionKey = keyof typeof privacyOptions;

  return (
    <>
      {showUpdateDialog && (
        <LeagueUpdateDiaglog
          league={league}
          handleHide={() => setShowUpdateDialog(false)}
        />
      )}
      <Copy>
        <SubHeading>League Details</SubHeading>
        <form onSubmit={handleSubmit}>
          <FieldWrap>
            <InputField
              id="ismLeagueName"
              label="League name"
              value={state.name}
              onChange={handleNameChange}
              hint="Maximum 30 characters"
              maxLength={30}
              disabled={league.name_change_blocked}
            />
          </FieldWrap>
          <p>
            {league.name_change_blocked
              ? "Your requested league name is currently pending moderation and can't be edited at this time."
              : "Please think carefully before entering your league name. Leagues with names that are deemed inappropriate or offensive may result in your account being deleted. Please refer to the Terms & Conditions of entry for more information."}
          </p>
          <FieldWrap>
            <SelectField
              id="ismLeagueCodePrivacy"
              value={state.codePrivacy}
              onChange={handleCodePrivacyChange}
              label="League code privacy"
              hint="This sets who the league code is viewable by"
            >
              {Object.keys(privacyOptions).map((key) => (
                <option
                  value={key}
                  selected={key === state.codePrivacy}
                  key={key}
                >
                  {privacyOptions[key as PrivacyOptionKey]}
                </option>
              ))}
            </SelectField>
          </FieldWrap>
          <FieldWrap>
            <CheckboxField
              checked={state.closed}
              id="ismLeagueClosed"
              label="Close league to new entries"
              onChange={handleClosedChange}
            />
          </FieldWrap>
          <Button type="submit" disabled={disabled}>
            Update league
          </Button>
        </form>
      </Copy>
    </>
  );
};

export default ClassicDetails;
