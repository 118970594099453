import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { RootState } from "../../rtk-core/src/app/store";
import {
  clearCode,
  getAutoJoinCode,
} from "../../rtk-core/src/features/autoJoin";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getRules } from "../../rtk-core/src/features/game";
import {
  getActiveEntryInLeague,
  getJoinPrivateLeagueError,
  getJoinPrivateLeagueSuccess,
  getLeagueFromCode,
  joinPrivateLeague,
} from "../../rtk-core/src/features/leagues";
import { checkLeagueCode } from "../../rtk-core/src/features/leagues/thunks";
import { getPlayerData } from "../../rtk-core/src/features/player";
import { formatErrorMsg, getErrorType } from "../../utils/leagueErrors";
import Alert from "../Alert";
import Button from "../Button";
import Dialog from "../Dialog";
import DialogBody from "../DialogBody";
import DialogHeader from "../DialogHeader";

const ConfirmBtnWrapper = styled.div`
  display: inline-block;
  margin-right: ${({ theme }) => theme.space[3]};
`;

const AutoJoinDialog: React.FC = () => {
  const reduxDispatch = useAppDispatch();
  const code = useSelector((state: RootState) => getAutoJoinCode(state));
  const navigate = useNavigate();
  const location = useLocation();
  const player = useSelector(getPlayerData);
  const error = useSelector((state: RootState) =>
    getErrorType(getJoinPrivateLeagueError(state))
  );
  const settings = useSelector((state: RootState) => getRules(state));
  const joinedPrivateLeagueSuccess = useSelector((state: RootState) =>
    getJoinPrivateLeagueSuccess(state)
  );

  // Most important part, if it returns null, nothing renders
  const league = useSelector((state: RootState) =>
    code ? getLeagueFromCode(state, code) : null
  );
  // Check if entry is already apart of the league
  const isPlayerInLeague = useSelector((state: RootState) =>
    league
      ? getActiveEntryInLeague(
          state,
          league.id,
          league.scoring === "c" ? "classic" : "h2h"
        )
      : null
  );

  const activeEvent = useAppSelector(getActiveEvent);

  // If we have a code, try and fetch the associated league. If there is an
  // issue fetching the league clear the code from local storage so we don't
  // try again
  useEffect(() => {
    // If you're logged in check the code
    // You might be logged out and we don't want a 403 forbidden
    if (player && player?.entry && code) {
      reduxDispatch(checkLeagueCode(code));
    }
  }, [player, code, reduxDispatch]);

  const handleHide = () => {
    reduxDispatch(clearCode());
  };

  const confirmJoinLeague = (code: string) => {
    reduxDispatch(joinPrivateLeague({ code }));
  };

  if (joinedPrivateLeagueSuccess && code && league) {
    handleHide();
    navigate(
      `/leagues/${league.id}/standings/${league.scoring}/${activeEvent.id}`
    );
    return null;
  }

  if (isPlayerInLeague && code && league) {
    const targetPath = `/leagues/${league.id}/standings/${league.scoring}/${activeEvent.id}`;
    if (location.pathname !== targetPath) {
      navigate(targetPath);
    }
  }

  return league ? (
    <Dialog closeDialog={handleHide}>
      <DialogHeader closeDialog={handleHide}>
        Join League{league && `: ${league.name}`}
      </DialogHeader>
      <DialogBody isPadded={true}>
        {isPlayerInLeague && (
          <>
            <div className="mb-2">
              <Alert $type="error">You are already in this league</Alert>
            </div>
          </>
        )}
        {!isPlayerInLeague && (
          <>
            {error ? (
              <>
                <div className="mb-2">
                  <Alert $type="error">
                    {settings && formatErrorMsg(error, settings)}
                  </Alert>
                </div>
              </>
            ) : (
              <>
                <div className="mb-2 text-center">
                  <p>Please press confirm to join league: {league.name}.</p>
                </div>
                <div className="flex justify-center">
                  {code && (
                    <ConfirmBtnWrapper>
                      <Button onClick={() => confirmJoinLeague(code)}>
                        Confirm
                      </Button>
                    </ConfirmBtnWrapper>
                  )}
                  <Button onClick={handleHide}>Cancel</Button>
                </div>
              </>
            )}
          </>
        )}
      </DialogBody>
    </Dialog>
  ) : null;
};

export default AutoJoinDialog;
