import { useAppSelector } from "../../rtk-core/src/app/hooks";
import { getOverallLeagueEntryForEntry } from "../../rtk-core/src/features/entries";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getMultiEventPhaseForEventId } from "../../rtk-core/src/features/phases";
import {
  getPlayerData,
  ILoggedInPlayer,
} from "../../rtk-core/src/features/player";
import { getTopPercentileString } from "../../utils/leagues";
import { getPhaseAssets } from "../../utils/phase";
import EventCarousel from "../EventCarousel";
import {
  PhaseDescription,
  PhaseEventsTitle,
  PhaseInfo,
  PhaseName,
  PhaseStat,
  PhaseStatHeading,
  PhaseStatMainHeading,
  PhaseStatMainValue,
  PhaseStats,
  PhaseStatsMain,
  PhaseStatsSecondary,
  PhaseStatValue,
  PhaseSummaryDivider,
  PhaseSummaryHeader,
  StyledPhaseSummary,
} from "./styles";

const PhaseSummary = () => {
  const activeEvent = useAppSelector(getActiveEvent);
  const eventPhase = useAppSelector((state) =>
    getMultiEventPhaseForEventId(state, activeEvent.id)
  );

  const player = useAppSelector(getPlayerData) as ILoggedInPlayer;
  const overallLeagueEntry = useAppSelector((state) =>
    player.entry ? getOverallLeagueEntryForEntry(state, player.entry) : null
  );

  if (!eventPhase) {
    return null;
  }

  const overallEventPhaseData = overallLeagueEntry?.active_phases.find(
    (p) => p.phase === eventPhase.id
  );

  const phaseAssets = getPhaseAssets(eventPhase.id);
  return (
    <StyledPhaseSummary>
      {/* Phase header */}
      <PhaseInfo>
        <PhaseSummaryHeader>
          <h2>
            <PhaseEventsTitle>
              Gameweek {eventPhase.start_event} - {eventPhase.stop_event}
            </PhaseEventsTitle>
            <PhaseName>{phaseAssets?.title}</PhaseName>
          </h2>
          <PhaseDescription>{phaseAssets?.description}</PhaseDescription>
        </PhaseSummaryHeader>
        {/* Phase scoreboard */}
        <PhaseStats>
          <PhaseStatsMain>
            <PhaseStatMainHeading>Event pts</PhaseStatMainHeading>
            <PhaseStatMainValue>
              {overallEventPhaseData && overallEventPhaseData.total
                ? overallEventPhaseData.total
                : "-"}
            </PhaseStatMainValue>
          </PhaseStatsMain>
          <PhaseStatsSecondary>
            <PhaseStat>
              <PhaseStatHeading>Global rank</PhaseStatHeading>
              <PhaseStatValue>
                {overallEventPhaseData?.entry_percentile_rank
                  ? getTopPercentileString(
                      overallEventPhaseData.entry_percentile_rank
                    )
                  : "-"}
              </PhaseStatValue>
            </PhaseStat>
            <PhaseStat>
              <PhaseStatHeading>Highest points</PhaseStatHeading>
              <PhaseStatValue>
                {eventPhase.highest_score !== null
                  ? eventPhase.highest_score
                  : "-"}
              </PhaseStatValue>
            </PhaseStat>
          </PhaseStatsSecondary>
        </PhaseStats>
      </PhaseInfo>
      <PhaseSummaryDivider />
      <EventCarousel phase={eventPhase} />
    </StyledPhaseSummary>
  );
};

export default PhaseSummary;
