import range from "lodash/range";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import EventCard from "../EventCard";
import { EventSlider, SliderWrap } from "./styles";
import { IEventCarousel } from "./types";

const EventCarousel = ({ phase }: IEventCarousel) => {
  const phaseEvents = range(phase.start_event, phase.stop_event + 1);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2.3,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.3,
        },
      },
    ],
  };
  return (
    <SliderWrap>
      <EventSlider {...settings}>
        {phaseEvents.map((e) => (
          <EventCard eventId={e} key={e} />
        ))}
      </EventSlider>
    </SliderWrap>
  );
};

export default EventCarousel;
