import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { RootState } from "../../rtk-core/src/app/store";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getRules } from "../../rtk-core/src/features/game";
import {
  getJoinPrivateLeagueError,
  getJoinPrivateLeagueSuccess,
  joinPrivateLeague,
} from "../../rtk-core/src/features/leagues";
import { formatErrorMsg, getErrorType } from "../../utils/leagueErrors";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import { FieldWrap, InputField } from "../FieldRenderers";
import { FormBackground } from "../FormBackground";
import HelmetHead from "../HelmetHead";
import { GridItem, GridWrapper, SidebarGrid } from "../Layout";
import Title from "../Title";

const JoinPrivate = () => {
  const [code, setCode] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const error = useSelector((state: RootState) =>
    getJoinPrivateLeagueError(state)
  );
  const settings = useSelector((state: RootState) => getRules(state));
  const hasJoinedPrivate = useSelector((state: RootState) =>
    getJoinPrivateLeagueSuccess(state)
  );

  const activeEvent = useAppSelector(getActiveEvent);

  useEffect(() => {
    if (hasJoinedPrivate) {
      navigate(`/leagues/${activeEvent.id}`);
    }
  }, [activeEvent, hasJoinedPrivate, navigate]);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCode(e.target.value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(joinPrivateLeague({ code }))
      .then(() => {
        navigate(`/leagues/${activeEvent.id}`);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const disabled = !code;
  const errorType = getErrorType(error);

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <HelmetHead
          title={"Create & Join Fantasy Football Leagues | PL Challenges"}
          description={
            "To view all types of leagues, as well as creating & joining new leagues, visit the official website of the Premier League."
          }
        />
        <GridItem $colSpan={1}>
          <FormBackground>
            <Copy>
              <Title>Join Invitational League</Title>
              {errorType && (
                <div className="mb-2">
                  <Alert $type="error">
                    {settings && formatErrorMsg(errorType, settings)}
                  </Alert>
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <FieldWrap>
                  <InputField
                    id="ismLeagueCode"
                    label="League code"
                    onChange={handleCodeChange}
                    value={code}
                  />
                </FieldWrap>
                <FieldWrap>
                  <Button type="submit" disabled={disabled}>
                    Join League
                  </Button>
                </FieldWrap>
              </form>
            </Copy>
          </FormBackground>
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export { JoinPrivate as JoinPrivateTest };
export default JoinPrivate;
