import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import { IEvent } from "./types";

export const getEventsById = (state: RootState) => state.events.byId;

export const getEvents = (state: RootState) =>
  Object.keys(state.events.byId).map((e) => state.events.byId[e]);

export const getEventByEventId = createSelector(
  [getEventsById, (state: RootState, eventId: number) => eventId],
  (events, eventId) => events[eventId]
);

export const getPreviousEvent = createSelector(getEvents, (events) => {
  const previousEvent = events.filter((e) => e.is_previous);
  return previousEvent.length ? previousEvent[0] : null;
});

export const getCurrentEvent = createSelector(getEvents, (events) => {
  const currentEvent = events.filter((e) => e.is_current);
  return currentEvent.length ? currentEvent[0] : null;
});

export const getNextEvent = createSelector(getEvents, (events) => {
  const nextEvent = events.filter((e) => e.is_next);
  return nextEvent.length ? nextEvent[0] : null;
});

export const getNonNullEvent = createSelector(
  [getCurrentEvent, getNextEvent],
  (currentEvent, nextEvent) => (currentEvent || nextEvent)!
);

export const getActiveEvent = (state: RootState) => state.events.activeEvent!;

export const getOpenEvent = createSelector(
  getEvents,
  (events) => events.find((event: IEvent) => event.can_enter)!
);

export const getFutureEvents = createSelector(
  getEvents,
  getNextEvent,
  (events, next) => (next ? events.filter((e) => e.id >= next.id) : [])
);

export const getFinishedEvents = createSelector(getEvents, (events) =>
  events.filter((e) => e.finished)
);

export const getLastEvent = createSelector(getEventsById, (eventsById) => {
  const ids = Object.keys(eventsById).map((id) => Number(id));
  if (!ids.length) {
    return null;
  }
  return eventsById[Math.max(...ids)];
});

export const getCurrentEventStatus = (state: RootState) =>
  state.events.currentEventStatus;
