import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../rtk-core/src/app/hooks";
import {
  IEvent,
  getActiveEvent,
  getEventsById,
} from "../../rtk-core/src/features/events";
import {
  ButtonText,
  ButtonWrap,
  ChevronLeftIcon,
  ChevronRightIcon,
  Container,
  EventTitle,
  NextButton,
  PreviousButton,
  TitleDateWrapper,
} from "./styles";

interface IEventNavigatorProps {
  getEventUrl: (event: IEvent) => string;
  enablePrev?: boolean;
}

const EventNavigator: React.FC<IEventNavigatorProps> = ({
  getEventUrl,
  enablePrev,
}) => {
  const navigate = useNavigate();

  const activeEvent = useAppSelector(getActiveEvent);
  const eventsById = useAppSelector(getEventsById);

  const setNextEvent = () => {
    const nextEvent = eventsById[activeEvent.id + 1];
    const nextUrl = getEventUrl(nextEvent);
    navigate(nextUrl);
  };

  const setPrevEvent = () => {
    const prevEvent = eventsById[activeEvent.id - 1];
    const prevUrl = getEventUrl(prevEvent);
    navigate(prevUrl);
  };

  const enabledPrev =
    enablePrev === undefined ? activeEvent.id > 1 : enablePrev;
  const enabledNext = activeEvent.id < Object.keys(eventsById).length;

  return (
    <Container>
      <ButtonWrap>
        <PreviousButton
          disabled={!enabledPrev}
          $variant="tertiary"
          onClick={setPrevEvent}
          $isFullWidth={true}
        >
          <ChevronLeftIcon color="white" />
          <ButtonText title="Previous">
            <span>Prev</span>
          </ButtonText>
        </PreviousButton>
      </ButtonWrap>

      <TitleDateWrapper>
        <EventTitle>{activeEvent.name}</EventTitle>
      </TitleDateWrapper>

      <ButtonWrap>
        <NextButton
          disabled={!enabledNext}
          $variant="tertiary"
          onClick={setNextEvent}
          $isFullWidth={true}
        >
          <ButtonText title="Next">
            <span>Next</span>
          </ButtonText>
          <ChevronRightIcon color="white" />
        </NextButton>
      </ButtonWrap>
    </Container>
  );
};

export default EventNavigator;
