import styled, { css } from "styled-components";
import Button, { IButtonStyledProps, SharedButtonStyles } from "../Button";
import { ChevronLeft, ChevronRight } from "../Icons/Chevrons";

export const ButtonWrap = styled.div`
  min-width: 0px;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    min-width: 150px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChevronStyles = css`
  width: ${({ theme }) => theme.space[3]};
  height: ${({ theme }) => theme.space[3]};
  margin: auto 0;
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
  ${ChevronStyles}
  margin-right:  ${({ theme }) => theme.space[2]};
`;

export const ChevronRightIcon = styled(ChevronRight)`
  ${ChevronStyles}
  margin-left: ${({ theme }) => theme.space[2]};
`;

export const EventTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heavy};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-style: normal;
  font-weight: 800;
  line-height: 4.7rem;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 2.5rem;
  }
`;

export const SpacingContainer = styled.div`
  padding-top: 3.2rem;
`;

export const TitleDateWrapper = styled.div`
  flex: 1 1 50%;
  align-self: center;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 1 1 60%;
  }
`;

const EventNavButtonStyles = css`
  ${SharedButtonStyles}
  justify-content:space-between;
  width: auto;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 100%;
  }
`;

export const PreviousButton = styled(Button)<IButtonStyledProps>`
  ${EventNavButtonStyles}
`;

export const NextButton = styled(Button)<IButtonStyledProps>`
  ${EventNavButtonStyles}
`;

export const ButtonText = styled.abbr`
  &[title] {
    cursor: pointer;
  }
  text-decoration: none;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    &::after {
      content: attr(title);
    }
    span {
      display: none;
    }
  }
`;
