export interface IChallengeCopy {
  description: string;
  detailDescription: string;
  detailTitle: string;
  filterDescription: string;
  filterLabel: string;
  title: string;
}

export interface EventAssets {
  copy: IChallengeCopy;
  lockupPath: string;
  featuredElementStatSort: string;
}

export const getChallengeAssets = (eventId: number) => {
  const challengeAssets: Record<number, EventAssets> = {
    1: {
      copy: {
        description: "Unlimited Budget, 5-a-side.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Debut",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    2: {
      copy: {
        description:
          "Goals are worth double points. Your squad may only contain 1 Goalkeeper and 4 Forwards.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "All Out Attack",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    3: {
      copy: {
        description:
          "Only players from the promoted teams, Ipswich, Southampton and Leicester City are available.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Promoted Punt",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
    4: {
      copy: {
        description:
          "Clean sheets are worth double points. Your squad may only contain 2 Goalkeepers and 3 Defenders.",
        detailDescription: "",
        detailTitle: "",
        filterDescription: "",
        filterLabel: "",
        title: "Too Big at the Back",
      },
      lockupPath: `/img/challenge/${eventId}/lockup.png`,
      featuredElementStatSort: "form",
    },
  };
  // check challengeAssets has a key for the event otherwise return null
  return Object.hasOwn(challengeAssets, eventId)
    ? challengeAssets[eventId]
    : null;
};
