import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { bootstrapApi } from "../api/bootstrap";
import { eventsApi } from "../api/events";
import { IGetBootstrapStaticResponse } from "../bootstrap";
import { IEvent, IEventState, IEventStatusData } from "./types";

const initialState: IEventState = {
  activeEvent: null,
  byId: {},
  currentEventStatus: null,
};

const events = createSlice({
  name: "events",
  initialState,
  reducers: {
    addEvents: (state, action: PayloadAction<IEvent[]>) => ({
      ...state,
      byId: Object.fromEntries(
        action.payload.map((event: IEvent) => [
          event.id,
          {
            ...event,
          },
        ])
      ),
    }),
    fetchEventStatus: (state, action: PayloadAction<IEventStatusData>) => ({
      ...state,
      currentEventStatus: { ...action.payload },
    }),
    setActiveEvent: (state, action: PayloadAction<IEvent>) => ({
      ...state,
      activeEvent: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        events.caseReducers.addEvents(state, {
          ...action,
          payload: action.payload.events,
        })
    );
    builder.addMatcher(
      eventsApi.endpoints.getEvents.matchFulfilled,
      (state, action: PayloadAction<IEvent[]>) =>
        events.caseReducers.addEvents(state, {
          ...action,
          payload: action.payload,
        })
    );
    builder.addMatcher(
      eventsApi.endpoints.getEventStatus.matchFulfilled,
      (state, action: PayloadAction<IEventStatusData>) =>
        events.caseReducers.fetchEventStatus(state, {
          ...action,
          payload: action.payload,
        })
    );
  },
});

export const { addEvents, setActiveEvent } = events.actions;

export default events.reducer;
