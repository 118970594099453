import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { getElementTypesById } from "../../rtk-core/src/features/elementTypes";
import { updateElementTypeControl } from "../../rtk-core/src/features/elements";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getRules } from "../../rtk-core/src/features/game";
import {
  IManagePickProposed,
  actionSubstitution,
} from "../../rtk-core/src/features/manage";
import ElementTypeLabel from "../ElementTypeLabel";
import PitchElement from "../PitchCard/PitchElement";
import { PitchCardButton, PitchPlaceholder } from "../PitchCard/styles";
import { AddPerson, ElementPosition } from "./styles";

interface IProps {
  pick: IManagePickProposed;
  showSidebar: () => void;
}

const SquadPitchUnit: React.FC<IProps> = ({ pick, showSidebar }) => {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(getRules);

  const activeEvent = useAppSelector(getActiveEvent);
  const elementTypes = useAppSelector((state) =>
    getElementTypesById(state, activeEvent.id)
  );

  const showElementType = (elementTypeId: number) => {
    dispatch(updateElementTypeControl(elementTypeId));
    showSidebar();
  };

  if (!settings) {
    return null;
  }

  const handleOnClick = () => {
    showElementType(pick.elementType);
    if (pick.subStatus === "target") {
      dispatch(actionSubstitution(pick.position));
    }
  };

  return (
    <>
      {pick.position > settings.squad_squadplay && (
        <ElementPosition>
          {elementTypes[pick.elementType].singular_name_short}
        </ElementPosition>
      )}
      {pick.element !== null && pick.element !== undefined ? (
        <PitchElement pick={pick} />
      ) : (
        <PitchCardButton onClick={handleOnClick}>
          <PitchPlaceholder>
            <AddPerson />
            <ElementTypeLabel elementType={elementTypes[pick.elementType]} />
          </PitchPlaceholder>
        </PitchCardButton>
      )}
    </>
  );
};

export default SquadPitchUnit;
