import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../rtk-core/src/app/hooks";
import { getActiveEvent } from "../../rtk-core/src/features/events";
import { getRules } from "../../rtk-core/src/features/game";
import {
  createClassicLeague,
  getCreateClassicLeagueError,
} from "../../rtk-core/src/features/leagues";
import { getErrorType } from "../../utils/leagueErrors";
import Button from "../Button";
import Copy from "../Copy";
import { FieldWrap, InputField } from "../FieldRenderers";
import { FormBackground } from "../FormBackground";
import HelmetHead from "../HelmetHead";
import { GridItem, GridWrapper, SidebarGrid } from "../Layout";
import Title from "../Title";

const CreateClassic = () => {
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const error = useSelector(getCreateClassicLeagueError);
  const settings = useSelector(getRules);
  const activeEvent = useAppSelector(getActiveEvent);

  // Event handlers
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    dispatch(
      createClassicLeague({
        name,
        has_cup: false,
        // An event id is necessary but for this game, the start event is
        // set on the backend to a default and therefore this is overriden
        start_event: activeEvent.id,
      })
    )
      .then(() => {
        navigate(`/leagues/${activeEvent.id}`);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Check if required fields are filled
  const disabled = !name || activeEvent.id === 0 || isSubmitting;

  return (
    <GridWrapper>
      <SidebarGrid $hasMargin={true}>
        <HelmetHead
          title={"Create & Join Fantasy Football Leagues | PL Challenges"}
          description={
            "To view all types of leagues, as well as creating & joining new leagues, visit the official website of the Premier League."
          }
        />
        <GridItem $colSpan={1}>
          <FormBackground>
            <Copy>
              <Title>Create Invitational League</Title>
              {error &&
                (getErrorType(error) === "max_private_entries" ? (
                  <p>
                    You may only enter {settings?.league_join_private_max}{" "}
                    invitational leagues
                  </p>
                ) : error ? (
                  <p>{JSON.stringify(error)}</p>
                ) : null)}
              <form onSubmit={handleSubmit}>
                <FieldWrap>
                  <InputField
                    id="ismLeagueName"
                    label="League name"
                    value={name}
                    onChange={handleNameChange}
                    hint="Maximum 30 characters"
                    maxLength={30}
                  />
                </FieldWrap>
                <div className="mb-4">
                  <p>
                    Please think carefully before entering your league name.
                    Leagues with names that are deemed inappropriate or
                    offensive may result in your account being deleted. Please
                    refer to the Terms & Conditions of entry for more
                    information.
                  </p>
                </div>
                <div className="mb-4">
                  <p>
                    In a league with classic scoring, teams are ranked based on
                    their total points in the game. You can join or league a
                    league with classic scoring at any point during the season.
                  </p>
                </div>
                <FieldWrap>
                  <Button type="submit" disabled={disabled}>
                    {isSubmitting ? "Please wait..." : "Create"}
                  </Button>
                </FieldWrap>
              </form>
            </Copy>
          </FormBackground>
        </GridItem>
      </SidebarGrid>
    </GridWrapper>
  );
};

export default CreateClassic;
