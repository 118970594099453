import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { bootstrapApi } from "../api/bootstrap";
import { IGetBootstrapStaticResponse } from "../bootstrap";
import { IGame, ISettings } from "./types";

const initialState: IGame = {
  settings: null,
  total_players: 0,
};

const game = createSlice({
  name: "game",
  initialState,
  reducers: {
    addGameSettings: (state, action: PayloadAction<ISettings>) => ({
      ...state,
      settings: action.payload,
    }),
    addTotalPlayers: (state, action: PayloadAction<number>) => ({
      ...state,
      total_players: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        game.caseReducers.addGameSettings(state, {
          ...action,
          payload: action.payload.game_settings,
        })
    );
    builder.addMatcher(
      bootstrapApi.endpoints.getBootstrapStatic.matchFulfilled,
      (state, action: PayloadAction<IGetBootstrapStaticResponse>) =>
        game.caseReducers.addTotalPlayers(state, {
          ...action,
          payload: action.payload.total_players,
        })
    );
  },
});

export const { addGameSettings, addTotalPlayers } = game.actions;

export default game.reducer;
