import { RootState } from "../../app/store";
import { INewEntry, IClassicStanding, IH2HStanding } from "./types";
import { getEntry, IEntry, ILeagueEntry } from "../entries";
import { getPlayerData } from "../player";
import { getPhasesById } from "../phases";

export const getH2HMatchesKey = (
  entryId?: number,
  eventId?: number,
  page?: number
) => `${entryId}-${eventId}-${page}`;

export const getLeagueAdmin = (state: RootState, leagueId: number) =>
  state.leagues.adminById[leagueId] || null;

export const getCode = (state: RootState, leagueId: number) =>
  state.leagues.codesByLeagueId[leagueId] || null;

export const getRenewableLeagues = (state: RootState) =>
  Object.values(state.leagues.renewableById);

export const getCreateClassicLeagueError = (state: RootState) =>
  state.leagues.createClassicLeagueError;

export const getCreateH2HLeagueError = (state: RootState) =>
  state.leagues.createH2HLeagueError;

export const getJoinPrivateLeagueError = (state: RootState) =>
  state.leagues.joinPrivateLeagueError;

export const getJoinPublicLeagueError = (state: RootState) =>
  state.leagues.joinPublicLeagueError;

export const getUpdateClassicLeagueError = (state: RootState) =>
  state.leagues.updateClassicLeagueError;

export const getUpdateH2HLeagueError = (state: RootState) =>
  state.leagues.updateH2HLeagueError;

export const getClassicLeague = (state: RootState, leagueId: number) => {
  const leagueData = state.leagues.classicStandingsById[leagueId];
  return leagueData ? leagueData.league : null;
};

export const getJoinPrivateLeagueSuccess = (state: RootState) =>
  typeof state.leagues.joinPrivateLeagueSuccess === "number";

const addEntryToTopOfNewEntries = (newEntries: INewEntry[], entry: IEntry) => [
  {
    entry: entry.id,
    entry_name: entry.name,
    player_first_name: entry.player_first_name,
    player_last_name: entry.player_last_name,
    joined_time: "", // We don't have so leave as a string
  },
  ...newEntries.filter((ne) => ne.entry !== entry.id),
];

export const getActiveEntryInLeague = (
  state: RootState,
  leagueId: number,
  scoring: "classic" | "h2h"
) => {
  const player = getPlayerData(state);
  if (player && player.entry) {
    const entry = getEntry(state, player.entry);
    if (entry) {
      const matches = entry.leagues[scoring].filter((l) => l.id === leagueId);
      if (matches.length) {
        return {
          entry,
          league: matches[0],
        };
      }
    }
  }
  return null;
};

const activeToClassicStanding = (
  entry: IEntry,
  league: ILeagueEntry,
  hasPlayed: boolean
): IClassicStanding => ({
  entry: entry.id,
  entry_name: entry.name,
  event_total: entry.summary_event_points,
  last_rank: league.entry_last_rank || 0,
  player_name: `${entry.player_first_name} ${entry.player_last_name}`,
  rank: league.entry_rank || 0,
  rank_sort: 0, // Need to add this
  total: entry.summary_overall_points, // Needs to be league
  has_played: hasPlayed,
  elo_badge: null,
  elo_events_until_ranked: null,
});

const activeToH2HStanding = (
  entry: IEntry,
  league: ILeagueEntry
): IH2HStanding => ({
  division: 0,
  entry: entry.id,
  entry_name: entry.name,
  last_rank: league.entry_last_rank || 0,
  matches_drawn: 0,
  matches_lost: 0,
  matches_played: 0,
  matches_won: 0,
  player_name: `${entry.player_first_name} ${entry.player_last_name}`,
  points_for: 0,
  rank: league.entry_rank || 0,
  rank_sort: 0,
  total: 0,
});

// TODO - getClassicNewEntries and getH2HNewEntries are almost the same,
// refactor.
export const getClassicNewEntries = (
  state: RootState,
  leagueId: number,
  page: number
) => {
  const leagueData = state.leagues.classicStandingsById[leagueId];
  if (
    leagueData &&
    leagueData.newEntriesByPage[page] &&
    leagueData.league.league_type !== "s"
  ) {
    if (page === 1) {
      const activeEntry = getActiveEntryInLeague(state, leagueId, "classic");
      if (activeEntry && !activeEntry.league.entry_rank) {
        return {
          ...leagueData.newEntriesByPage[page],
          results: addEntryToTopOfNewEntries(
            leagueData.newEntriesByPage[page].results,
            activeEntry.entry
          ),
        };
      }
    }
    return leagueData.newEntriesByPage[page];
  }
  return null;
};

export const getClassicStandings = (
  state: RootState,
  leagueId: number,
  phaseId: number,
  page: number
) => {
  const leagueData = state.leagues.classicStandingsById[leagueId];
  if (
    leagueData &&
    leagueData.standingsByPhaseAndPage[phaseId] &&
    leagueData.standingsByPhaseAndPage[phaseId][page]
  ) {
    const standings = leagueData.standingsByPhaseAndPage[phaseId][page];
    const activeEntry = getActiveEntryInLeague(state, leagueId, "classic");
    if (
      standings.results.length &&
      activeEntry &&
      activeEntry.league.entry_rank
    ) {
      const phase = getPhasesById(state)[phaseId];
      const has_played =
        activeEntry.entry.entered_events.some(
          (eventId) =>
            eventId >= phase.start_event && eventId <= phase.stop_event
        ) || false;
      if (!standings.results.some((cs) => cs.entry === activeEntry.entry.id)) {
        return {
          ...standings,
          results:
            activeEntry.league.entry_rank <= standings.results[0].rank
              ? [
                  activeToClassicStanding(
                    activeEntry.entry,
                    activeEntry.league,
                    has_played
                  ),
                  ...standings.results,
                ]
              : [
                  ...standings.results,
                  activeToClassicStanding(
                    activeEntry.entry,
                    activeEntry.league,
                    has_played
                  ),
                ],
        };
      }
    }
    return standings;
  }
  return null;
};

export const getH2HLeague = (state: RootState, leagueId: number) => {
  const leagueData = state.leagues.h2hStandingsById[leagueId];
  return leagueData ? leagueData.league : null;
};

export const getH2HNewEntries = (
  state: RootState,
  leagueId: number,
  page: number
) => {
  const leagueData = state.leagues.h2hStandingsById[leagueId];
  if (leagueData && leagueData.newEntriesByPage[page]) {
    // Only add on 1st page and if we have some newEntries
    if (page === 1 && leagueData.newEntriesByPage[page].results.length) {
      const activeEntry = getActiveEntryInLeague(state, leagueId, "h2h");
      if (activeEntry && !activeEntry.league.entry_rank) {
        return {
          ...leagueData.newEntriesByPage[page],
          results: addEntryToTopOfNewEntries(
            leagueData.newEntriesByPage[page].results,
            activeEntry.entry
          ),
        };
      }
    }
    return leagueData.newEntriesByPage[page];
  }
  return null;
};

export const getH2HStandings = (
  state: RootState,
  leagueId: number,
  page: number
) => {
  const leagueData = state.leagues.h2hStandingsById[leagueId];
  if (leagueData && leagueData.standingsByPage[page]) {
    const standings = leagueData.standingsByPage[page];
    const activeEntry = getActiveEntryInLeague(state, leagueId, "h2h");
    if (
      activeEntry &&
      activeEntry.league.entry_rank &&
      standings.results.length
    ) {
      if (!standings.results.some((cs) => cs.entry === activeEntry.entry.id)) {
        return {
          ...standings,
          results:
            activeEntry.league.entry_rank <= standings.results[0].rank
              ? [
                  activeToH2HStanding(activeEntry.entry, activeEntry.league),
                  ...standings.results,
                ]
              : [
                  ...standings.results,
                  activeToH2HStanding(activeEntry.entry, activeEntry.league),
                ],
        };
      }
    }
    return standings;
  }
  return null;
};

export const getH2HMatches = (
  state: RootState,
  leagueId: number,
  entryId: number,
  eventId: number,
  page: number
) => {
  const key = getH2HMatchesKey(entryId, eventId, page);
  return state.leagues.h2hMatchesById[leagueId]?.byEntryEventPage[key] || null;
};

export const getLeagueFromCode = (state: RootState, code: string) =>
  state.leagues.byCode[code] || null;

export const getLeagueEntries = (state: RootState, leagueId: number) =>
  state.leagues.entriesById[leagueId] || null;

export const getLeagueEntriesError = (state: RootState, leagueId: number) =>
  state.leagues.entriesErrorById[leagueId] || null;

export const getAllLeagueCupStatus = (state: RootState) =>
  state.leagues.cupStatusById;

export const getLeagueCupStatus = (state: RootState, leagueId: number) =>
  state.leagues.cupStatusById[leagueId] || null;
